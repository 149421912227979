
$body-bg: #eee;

@import "~bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


div.loading-screen {
  display:block;
  //width:100%;
  text-align:center;
  img.logo {
    max-width: 200px;
    display:inline;
  }
  .loading {
    display:block;
    animation: bounce 1.5s linear infinite;
    height: 100px;
    width: 100px;
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0px);
      animation-timing-function: ease-out;
    }
    50% {
      transform: translateY(-30px);
      animation-timing-function: ease-in;
    }
  }

}

form.item-search {
  input[type=text] {
    outline: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.card.line-item {
  margin-top:1.5em;
  button {
    margin-right:0.5em;
  }
  p.card-text {
    font-size:0.8em;
  }
  .error {
    display: block;
    color: #d00;
  }
  .images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    > button {
      display:flex;
      box-sizing: content-box;
      border-radius: 6px;
      background-color:#eee;
      align-items:center;
      padding:6px;
      margin-bottom:8px;
      height:300px;
      width:300px;
      border: 0;
      //outline: 1px dotted #ccc;
      box-shadow: 8px 8px 24px #ccc;
      img {
        max-width:100%;
        max-height:300px;
        margin:auto;
        display:block;
        //box-shadow: #666666 2px 2px 5px;
      }

    }
  }
}

label.form-label {
  font-size:1em;
}

div.portal {
  position: fixed;
  top:0;
  left:0;
  height:100%;
  width:100%;
  background-color: rgba(25,25,25,0.8);
  z-index:1050;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
}

div.image-editor {
  height: 100%;
  width: 100%;
  outline: 1px solid pink;
  .image-container {
    height: 80%;
    padding:1em;
    border: 1px dotted #666666;
  }
  .buttons {
    position: fixed;
    height: 100px;
    width: 100%;
    bottom:0;
    right:0;
    display:flex;
    justify-content: space-evenly;
    align-items:center;
    //background-color: #cccccc;
  }
}


// div.image-editor {
//   height: 100%;
//   width: 100%;
//   //outline: 1px solid pink;
//   .image-container {
//     height: 80%;
//     padding:1em;
//     border: 1px dotted #666666;
//     .ReactCrop {
//       max-height:100%;
//       max-width:100%;
//       // display:flex;
//       background-color: rgba(0,0,0,0);
//       //justify-content: flex-start;
//       //align-items: flex-start;
//     }
//     img {
//       object-fit:contain;
//       max-width: 100%;
//       max-height: 100%;
//     }
//   }
//   .buttons {
//     position: fixed;
//     height: 100px;
//     width: 100%;
//     bottom:0;
//     right:0;
//     display:flex;
//     justify-content: space-evenly;
//     align-items:center;
//     //background-color: #cccccc;
//   }
// }

input[type=file] {
  visibility:hidden;
  position:absolute;
  display: none;
}
