.App {
  /* Trying to resort to react-bootstrap components */
  /*text-align: center;*/
  /* max-width:765px; */
  /* margin-left:auto; */
  /* margin-right:auto; */
}

.auth-form {
  margin:0.5em;
  padding:0.5em;
}

.auth-form .card-title h5 {

}
/*.App-logo {*/
  /*animation: App-logo-spin infinite 20s linear;*/
  /*height: 40vmin;*/
/*}*/

/*.App-header {*/
  /*background-color: #282c34;*/
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
/*}*/

/*.App-link {*/
  /*color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
  /*from {*/
    /*transform: rotate(0deg);*/
  /*}*/
  /*to {*/
    /*transform: rotate(360deg);*/
  /*}*/
/*}*/
